// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FAQ_wrapper__SYCEg .FAQ_question__8qBaZ {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
}
.FAQ_wrapper__SYCEg .FAQ_answer__cInL1 {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/FAQ/FAQ.module.scss"],"names":[],"mappings":"AAGI;EACI,WAAA;EACA,eAAA;EACA,gBAAA;AAFR;AAKI;EACI,WAAA;EACA,eAAA;EACA,gBAAA;AAHR","sourcesContent":["@import \"styles/mixins.scss\";\r\n\r\n.wrapper{\r\n    .question{\r\n        color: #fff;\r\n        font-size: 24px;\r\n        font-weight: 400;\r\n    }\r\n    \r\n    .answer{\r\n        color: #fff;\r\n        font-size: 18px;\r\n        font-weight: 300;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FAQ_wrapper__SYCEg`,
	"question": `FAQ_question__8qBaZ`,
	"answer": `FAQ_answer__cInL1`
};
export default ___CSS_LOADER_EXPORT___;
