export const navbarItems = [
    {
      path: "#games",
      slug: "Games",
      state: null,
    },
    {
      path: "#payzone",
      slug: "Payzone",
      state: null,
    },
    {
      path: "/steam",
      slug: "Steam services",
      state: null,
    },
    {
      path: "#contact",
      slug: "Contact",
      state: null,
    },
  ];